<template>
  <div class="max-w-[96rem] mx-auto">
    <st-title
      :title="groupName"
      class="!mt-0 !mb-[4.6rem]"
      :navItems="[
        {
          name: $t('studio.gnb.group_myinfo.my_group.detailed_group_breadcrumb1'),
          url: 'my-info'
        },
        {
          name: $t('studio.gnb.group_myinfo.my_group.detailed_group_breadcrumb2')
        }
      ]"
    >
      <template #join-info>
        <span class="text-xl leading-xl font-bold text-on-surface-elevation-2">
          {{ $t(groupRoleLabel) }}
        </span>
        <span class="text-md leading-sm font-regular text-on-surface-elevation-3">
          {{
            `${$t('studio.gnb.group_myinfo.my_group.detailed_group_join_time', {
              dateTime: formatDateTime(groupDate, DATE_TIME_FORMAT_WITH_DOT)
            })}`
          }}
        </span>
      </template>
    </st-title>
    <st-box>
      <h3 class="text-2xl leading-xl font-bold text-on-surface-elevation-2">
        {{ $t('studio.gnb.group_myinfo.my_group.detailed_group_permission') }}
      </h3>
      <m-table class="mt-16">
        <colgroup>
          <col style="width: auto" />
          <col style="width: 9.6rem" />
          <col style="width: 10.4rem" />
        </colgroup>
        <thead>
          <tr>
            <st-th :thTitle="$t('studio.gnb.group_myinfo.my_group.detailed_group_per_by_menu')" />
            <st-th
              aCenter
              :thTitle="$t('studio.gnb.group_myinfo.my_group.detailed_group_per_view')"
            />
            <st-th
              aCenter
              :thTitle="$t('studio.gnb.group_myinfo.my_group.detailed_group_per_edit')"
              class="flex gap-4 w-full justify-center"
            >
              <button type="button" class="h-20" @click="openEditingPermissionDialog">
                <s-icon
                  size="xl"
                  icon="ic-v2-state-help-circle-line"
                  class="text-on-surface-elevation-4 flex"
                />
              </button>
            </st-th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in menus" :key="`permission_${idx}`" class="group">
            <td
              class="h-36 align-middle px-12 text-md text-left font-regular text-on-surface-elevation-3 first:pl-20 last:pr-20 group-first:pt-8 group-first:h-44"
            >
              {{ $t(getMenuLabelById(item.id || '')) }}
            </td>
            <td
              class="h-36 align-middle px-12 text-md text-center font-regular text-on-surface-elevation-3 first:pl-20 last:pr-20 group-first:pt-8 group-first:h-44"
            >
              <s-icon
                icon="ic-v2-control-check-bold-line"
                size="3xl"
                :class="{
                  'text-on-surface-elevation-1': item.viewable,
                  'text-disabled-variant-2': !item.viewable
                }"
                class="flex mx-auto"
              />
            </td>
            <td
              class="h-36 align-middle px-12 text-md text-center font-regular text-on-surface-elevation-3 first:pl-20 last:pr-20 group-first:pt-8 group-first:h-44"
            >
              <s-icon
                icon="ic-v2-control-check-bold-line"
                size="3xl"
                :class="{
                  'text-on-surface-elevation-1': item.editable,
                  'text-disabled-variant-2': !item.editable
                }"
                class="flex mx-auto"
              />
            </td>
          </tr>
        </tbody>
      </m-table>
    </st-box>
    <st-box class="mt-24">
      <div class="flex gap-8 items-center mb-8">
        <s-button variant="outline" size="xs" @click="onCollapseAll">
          {{ $t('studio.gnb.group_myinfo.my_group.detailed_group_collapse_all_btn') }}
        </s-button>
        <s-button variant="outline" size="xs" @click="onExpandAll">
          {{ $t('studio.gnb.group_myinfo.my_group.detailed_group_expand_all_btn') }}
        </s-button>
      </div>
      <st-table :columns="projectColumns" :data="sortedProjects">
        <template #row="{ row: project, index: indexProject }">
          <tr>
            <td colspan="2">
              <div class="flex flex-col gap-8 mt-8">
                <st-folding
                  v-model:isOpenDetail="project.expand"
                  projectType
                  :name="project.name"
                  :projectCount="project.count.toString()"
                  @onFold="onExpand(indexProject, $event)"
                >
                  <div class="mt-16 mx-24 mb-20">
                    <st-table showHoverEffect :showCursorPointer="false" :columns="productColumns" :data="project.products ?? []">
                      <template #col-1="{ row: product }">
                        <st-td aLeft :tdValue="getMemberRoleName(product.roleName)">
                          <button
                            type="button"
                            class="h-16 align-middle ml-4 relative top-[-.1rem]"
                            @click="openRolePermissionDialog(product.roleNo)"
                          >
                            <s-icon
                              size="xl"
                              icon="ic-v2-state-help-circle-line"
                              class="text-on-surface-elevation-4 flex"
                            />
                          </button>
                        </st-td>
                      </template>
                      <template #empty>
                        <st-td colspan="3" aCenter>
                          <s-text
                            as="span"
                            class="flex items-center justify-center h-120 text-on-surface-elevation-4"
                            :class="{ 'opacity-0': isLoading }"
                          >
                            {{ $t('studio.gnb.group_myinfo.my_group.detailed_group_prj_num_none') }}
                          </s-text>
                        </st-td>
                      </template>
                    </st-table>
                  </div>
                </st-folding>
              </div>
            </td>
          </tr>
        </template>
        <template #empty>
          <st-td colspan="2" aCenter>
            <s-text
              as="span"
              class="flex items-center justify-center h-120 text-on-surface-elevation-4"
              :class="{ 'opacity-0': isLoading }"
            >
              {{ $t('studio.gnb.group_myinfo.my_group.detailed_group_prj_num_none') }}
            </s-text>
          </st-td>
        </template>
      </st-table>
    </st-box>
    <div class="mt-40 flex justify-center gap-16">
      <s-button
        v-if="!hasOwnership"
        variant="red"
        size="lg"
        class="!min-w-160"
        @click="onUnSubscribe"
      >
        {{ $t('studio.gnb.group_myinfo.my_group.detailed_group_btn_leave_group') }}
      </s-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchMenuList } from '@/apis/member.api';
import { fetchProductsByProjectIdApi, leaveGroupApi } from '@/apis/my-info.api';
import StBox from '@/components/common/st-box.vue';
import StFolding from '@/components/common/st-folding.vue';
import StTitle from '@/components/common/st-title.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import EditingPermissionDialog from '@/components/my-info/editing-permission-dialog.vue';
import RolePermissionDialog from '@/components/my-info/role-permission-dialog.vue';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { DATE_TIME_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { INVITE_STATE } from '@/constants/my-info.const';
import {
  ROLE_DEVELOPER,
  ROLE_MARKETER,
  ROLE_OPERATOR,
  ROLE_OWNER,
  ROLE_PROJECT_MANAGER
} from '@/constants/permission.const';
import { MY_INFO_PAGE_URL } from '@/constants/url.const';
import { Confirmation, Permission } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import useMyInfoStore from '@/stores/my-info.store';
import { useUserStore } from '@/stores/user.store';
import {
  type MenuRole as MenuAuthorityRole,
  type MenuRole
} from '@/types/member/member-request.type';
import type { GroupAuthResponse, GroupDetailResponse } from '@/types/my-info/my-info-response.type';
import type { ProductModel, ProjectModel } from '@/types/project/project-model.type';
import type { ProjectResponse } from '@/types/project/project-response.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { redirectTo } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';
import { getMenuLabelById } from '@/utils/my-info.util';
import { clientSideSort } from '@/utils/table.util';
import { definePageMeta } from '#imports';

definePageMeta({
  layout: 'empty',
  middleware: ['my-group-middleware']
});

const { t, locale } = useI18n();
const route = useRoute();
const groupId = route.params.id as string;

const userStore = useUserStore();
const myInfoStore = useMyInfoStore();

const appStore = useAppStore();
const { isLoading } = storeToRefs(appStore);

const { groupDetail } = storeToRefs(myInfoStore);
const { fetchSimpleMyInfos } = userStore;
const group = ref<GroupDetailResponse>();
const menus = ref<MenuRole[]>([]);

const sortedProjects = ref<ProjectModel[]>([]);
const originProjectProducts = ref<ProjectModel[]>([]);

const hasOwnership = computed(() => {
  if (group.value) {
    return group.value.groupInfo.ownerYn === Confirmation.YES;
  }
  return true;
});
const groupName = computed(() => group.value?.groupInfo.groupName || '-');
const groupDate = computed(() =>
  group.value?.groupInfo.inviteStatus === INVITE_STATE.APPLY
    ? group.value?.groupInfo.invitedAt ?? 0
    : group.value?.groupInfo.joinedAt ?? 0
);
const groupRoleLabel = computed(() =>
  hasOwnership.value
    ? 'studio.gnb.group_myinfo.my_group_role_type_owner'
    : 'studio.gnb.group_myinfo.my_group_role_type_mem'
);
const projects = computed<ProjectModel[]>(
  () =>
    group.value?.projects.map((project: ProjectResponse) => ({
      id: project.projectId,
      name: project.projectName,
      count: project.productCount ?? 0,
      expand: true
    })) || []
);

const getMemberRoleName = (roleName?: string) => {
  if (!roleName) {
    return '';
  }
  switch (roleName) {
    case ROLE_OWNER:
      return t('studio.gnb.group_myinfo.my_group_role_type_owner');
    case ROLE_DEVELOPER:
      return t('studio.member_manage.role_value_developer');
    case ROLE_MARKETER:
      return t('studio.member_manage.role_value_marketer');
    case ROLE_OPERATOR:
      return t('studio.member_manage.role_value_operator');
    case ROLE_PROJECT_MANAGER:
      return t('studio.member_manage.role_value_manager');
    default:
      return roleName;
  }
};

const onSortProducts = (sortValue: number, key: string, products: ProductModel[]) => {
  if (products.length === 0) {
    return;
  }
  const sortedProjectsIndex = sortedProjects.value.findIndex(
    (project: ProjectModel) => project.id === products[0].projectId
  );

  const projectsIndex = originProjectProducts.value.findIndex(
    (project: ProjectModel) => project.id === products[0].projectId
  );

  if (projectsIndex === -1 || sortedProjectsIndex === -1) {
    return;
  }

  sortedProjects.value[sortedProjectsIndex].products = clientSideSort(
    cloneDeep(originProjectProducts.value[projectsIndex].products)!,
    key,
    sortValue,
    locale.value
  );
};

const productColumns = ref<Column[]>([
  {
    title: 'studio.gnb.group_myinfo.my_group.detailed_group_prj_product_name',
    width: 'auto',
    align: 'left',
    dataIndex: 'name',
    sortKey: 'name',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey, data }: ColumnSorting) =>
      onSortProducts(sortValue, sortKey, data)
  },
  {
    title: 'studio.gnb.group_myinfo.my_group.detailed_group_prj_role',
    width: '20rem',
    align: 'left',
    dataIndex: 'roleName',
    sortKey: 'roleName',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey, data }: ColumnSorting) =>
      onSortProducts(sortValue, sortKey, data)
  },
  {
    title: 'studio.gnb.group_myinfo.my_group.detailed_group_permission_given_date',
    width: '16.8rem',
    align: 'left',
    sortKey: 'roleGrantedAt',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey, data }: ColumnSorting) =>
      onSortProducts(sortValue, sortKey, data),
    render: (product: ProductModel) =>
      formatDateTime(product.roleGrantedAt, DATE_TIME_FORMAT_WITH_DOT)
  }
]);

const projectColumns = ref<Column[]>([
  {
    title: 'studio.gnb.group_myinfo.my_group.detailed_group_prj_name',
    width: 'auto',
    sortKey: 'name',
    titleTooltip: 'studio.gnb.group_myinfo.my_group.detailed_group_prj_name',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortProjects(sortValue, sortKey)
  },
  {
    title: 'studio.gnb.group_myinfo.my_group.detailed_group_product_num',
    width: '16.2rem',
    sortKey: 'count',
    titleTooltip: 'studio.gnb.group_myinfo.my_group.detailed_group_product_num',
    thClass: 'bg-[#F7FAFD]',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortProjects(sortValue, sortKey)
  }
]);

const onExpand = async (index: number, value: boolean) => {
  if (value) {
    await setProductsToProject(index);
  }
  sortedProjects.value[index].expand = value;
};

const onCollapseAll = () => {
  sortedProjects.value.forEach((project: ProjectModel) => {
    project.expand = false;
  });

  originProjectProducts.value.forEach((project: ProjectModel) => {
    project.expand = false;
  });
};

const onExpandAll = () => {
  if (projects.value.length === 0) {
    return;
  }
  for (let i = 0; i < projects.value.length; i++) {
    onExpand(i, true);
  }
};

const setProductsToProject = async (index: number) => {
  const result = await fetchProductsByProjectIdApi(
    groupId as string,
    sortedProjects.value[index].id
  );
  if (!result) {
    return;
  }
  sortedProjects.value[index].products = cloneDeep(result.products);
  const projectsIndex = originProjectProducts.value.findIndex(
    (project: ProjectModel) => project.id === sortedProjects.value[index].id
  );

  if (projectsIndex === -1) {
    return;
  }

  originProjectProducts.value[projectsIndex].products = result.products;
};

const onUnSubscribe = async () => {
  const result = await showConfirm({
    content: t('studio.gnb.group_myinfo.my_group.detailed_group_leave_group_msg'),
    confirmLabel: t('studio.gnb.group_myinfo.my_group.detailed_group_btn_leave_cf'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    confirmVariant: 'red',
    cancelVariant: 'outline'
  });
  if (!result) {
    return;
  }
  try {
    const success = await leaveGroupApi(groupId);
    if (!success) {
      return;
    }
    const result = await showAlert({
      severity: 'info',
      content: t('studio.gnb.group_myinfo.my_group.detailed_group_leave_done_msg')
    });
    if (!result) {
      return;
    }
    await fetchSimpleMyInfos();
    await redirectTo(MY_INFO_PAGE_URL);
  } catch (e) {
    await showAlert({
      severity: 'info',
      content: t('studio.gnb.group_myinfo.my_group.detailed_group_click_error_msg')
    });
  }
};

const openEditingPermissionDialog = async () => {
  await showDialog({
    component: shallowRef(EditingPermissionDialog),
    props: {
      header: 'Editing Permission'
    },
    severity: 'info'
  });
};

const openRolePermissionDialog = async (roleNo: number) => {
  await showDialog({
    component: shallowRef(RolePermissionDialog),
    props: {
      header: 'Role Permission',
      roleNo,
      groupId
    },
    severity: 'info'
  });
};

const fetchAllMenu = async () => {
  const result = await fetchMenuList();
  if (!result) {
    return;
  }

  menus.value = result.map((menu: MenuAuthorityRole) => {
    const data = group.value?.groupAuth.find(
      (authority: GroupAuthResponse) => authority.authorityId === menu.id
    );

    menu.viewable = data?.permission === Permission.READ || data?.permission === Permission.WRITE;
    menu.editable = data?.permission === Permission.WRITE;

    return menu;
  });
};

const init = async () => {
  if (!groupDetail.value) {
    return;
  }

  group.value = cloneDeep(groupDetail.value);
  await fetchAllMenu();

  sortedProjects.value = cloneDeep(projects.value);
  originProjectProducts.value = cloneDeep(projects.value);
  // expand all project
  if (projects.value.length > 0) {
    onExpandAll();
  }
};

const onSortProjects = (sortValue: number, key: string) => {
  onCollapseAll();
  sortedProjects.value = clientSideSort(
    cloneDeep(originProjectProducts.value),
    key,
    sortValue,
    locale.value
  );
};

init();
</script>
